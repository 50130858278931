<template>
  <div class="row">
    <div class="col-6">
      <h4>Items</h4>
    </div>
    <div class="col-6 text-right">
      <button class="btn btn-sm btn-outline-info mr-2">Upload</button>
      <button class="btn btn-sm btn-info" @click="addItem">Add</button>
    </div>
  </div>

  <div class="row mt-2">
    <div class="col-12">
      <table-component :columns="columns"
                       :rows="doctors"
                       :sortable="sortable"
                       @sorting="sorting"
                       :search="false"
                       :pagination="pagination"
                       @search="search"
                       @pageChanged="pageChanged" />
    </div>
  </div>

  <item-form-modal ref="itemFormModal" />
</template>

<script>
import TableComponent from "@/components/TableComponent.vue";
import ItemFormModal from "./ItemFormModal.vue";

export default {
  components: {TableComponent, ItemFormModal},
  data() {
    return {
      doctors: [],
      pagination: null,
      queryParams: {
        status: '',
        doctor_id: '',
        page: 1,
        search: '',
        sort: 'visit_no',
        sort_order: 'desc',
        per_page: 10
      },
      sortable: {
        order: 'visit_no',
        sort: 'desc'
      },
      columns: [
        {
          label: "Item Name / SKU",
          field: "visit_no",
          sortable: true,
          searchable: true,
        },
        {
          label: "Description",
          field: "patient.hn",
          sortable: true,
          searchable: true,
        },
        {
          label: "Category",
          field: "patient.full_name",
          sortable: false,
          searchable: true,
        },
        {
          label: "Price",
          field: "doctors",
          sortable: false,
          searchable: false,
          slot: true
        },
        {
          label: "Status",
          field: 'date',
          sortable: true,
          searchable: false,
        },
        {
          label: "Action",
          field: "action",
          sortable: false,
          searchable: false,
          slot: true,
          class: ['text-right']
        },
      ],
    }
  },
  methods: {
    addItem() {
      this.$refs.itemFormModal.show();
    }
  }
}
</script>