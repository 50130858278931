<template>
  <div class="modal fade" id="item-form-modal" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Create Item</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-6">
              <div class="form-group">
                <label>Name</label>
                <input class="form-control" type="text">

                <v-errors :errors="errorFor('password')"></v-errors>
              </div>

              <div class="form-group">
                <label>Description</label>
                <textarea class="form-control" rows="4"></textarea>

                <v-errors :errors="errorFor('password')"></v-errors>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <label>Category</label>
                <input class="form-control" type="text">

                <v-errors :errors="errorFor('password')"></v-errors>
              </div>

              <div class="form-group">
                <label>Price</label>
                <input class="form-control" type="text">

                <v-errors :errors="errorFor('password')"></v-errors>
              </div>

              <div class="checkbox">
                <label>
                  <input type="checkbox" value="">
                  Active
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-sm btn-secondary" data-dismiss="modal">Close</button>
          <button type="button"
                  :disabled="loading"
                  @click="createWorkspace"
                  class="btn btn-sm btn-success">Create</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        name: '',
        description: '',
      }
    }
  },
  methods: {
    show() {
      this.form.name = '';
      this.allErrors = null;

      window.$('#item-form-modal').modal('show')
    },
    hide() {
      window.$('#item-form-modal').modal('hide')
    },
    createWorkspace() {
      this.loading = true;
      this.allErrors = null;

      this.axios.post('/workspaces', this.form)
          .then(() => {
            this.$emit('created');
            this.hide();
          })
          .catch((err) => {
            this.allErrors = err.response.data.errors;
          })
          .finally(() => {
            this.loading = false;
          })
    }
  }
}
</script>