<template>
  <page-title title="Settings" />

  <div class="content">
    <div class="card">
      <div class="card-body">
        <ul class="nav nav-tabs" role="tablist">
          <li class="nav-item">
            <router-link class="nav-link"
                         role="tab"
                         :to="{name: 'settings_account'}"
                         active-class="active">
              <span class="hidden-sm-up"><i class="fa-solid fa-user"></i></span> <span class="hidden-xs-down">Account</span>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link"
                         role="tab"
                         :to="{name: 'settings_members'}"
                         active-class="active">
              <span class="hidden-sm-up"><i class="fa-solid fa-users"></i></span> <span class="hidden-xs-down">Members</span>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link"
                         role="tab"
                         :to="{name: 'settings_items'}"
                         active-class="active">
              <span class="hidden-sm-up"><i class="fa-solid fa-warehouse"></i></span> <span class="hidden-xs-down">Items</span>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link"
                         role="tab"
                         :to="{name: 'settings_notification'}"
                         active-class="active">
              <span class="hidden-sm-up"><i class="fa-solid fa-bell"></i></span> <span class="hidden-xs-down">Notification</span>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link"
                         role="tab"
                         :to="{name: 'settings_billing'}"
                         active-class="active">
              <span class="hidden-sm-up"><i class="fa-solid fa-money-bill"></i></span> <span class="hidden-xs-down">Billing & Subscription</span>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link"
                         role="tab"
                         :to="{name: 'settings_security'}"
                         active-class="active">
              <span class="hidden-sm-up"><i class="fa-solid fa-lock"></i></span> <span class="hidden-xs-down">Security</span>
            </router-link>
          </li>
        </ul>
        <!-- Tab panes -->
        <div class="tab-content">
          <div class="pad-20">
            <router-view />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PageTitle from "@/components/layouts/PageTitle.vue";

export default {
  components: {PageTitle}
}
</script>